export var headers = {
	"Project-Id-Version": "open-xchange-xfinity-ui",
	"Report-Msgid-Bugs-To": "",
	"POT-Creation-Date": "2023-03-16 09:58+0000",
	"PO-Revision-Date": "2023-03-16 09:58+0000",
	"Last-Translator": "NAME <EMAIL>",
	Language: "en_US",
	"Language-Team": "OX <i18n@open-xchange.com>",
	"Content-Type": "text/plain; charset=UTF-8",
	"Content-Transfer-Encoding": "8bit",
	"Plural-Forms": "nplurals=2; plural=(n!=1);",
	"MIME-Version": "1.0"
};
export default {
	headers: headers
};
